<template>
  <div class="interview-history">
    <div class="chat-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/course.png`)" fit="contain"></el-image>
        <div class="head-title">AI模拟面试</div>
      </div>
      <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
    </div>
    <div class="chat-main">
      <div class="main-head">
        <el-image class="ai-title" :src="require(`../../../assets/image/aititle.png`)" fit="contain"></el-image>
        <div class="head-content" v-if="interview_history">
          <i class="el-icon-s-custom"></i>
          <span class="name">姓名：{{ interview_history[0].name === null ? '暂未实名' : interview_history[0].name }}</span>
          <span class="name">手机：{{ interview_history[0].cell_phone === null ? '暂未实名' : interview_history[0].cell_phone }}</span>
          <span class="name">学校：{{ interview_history[0].school === null ? '暂未实名' : interview_history[0].school }}</span>
        </div>
      </div>
      <div class="main-data" v-for="item of interview_history" :key="item.interview_history_id">
        <div class="data-question-topic">{{ item.question_topic }}</div>
        <div class="data-result">
          <div class="title">候选人:</div>
          <div class="result">{{ item.result }}</div>
        </div>
        <div class="data-analysis">
          <span class="title">AI评价:</span>
          <span class="analysis">{{ item.analysis }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInterviewHistory } from '../../../request/api'

export default {
  data() {
    return {
      history_id: '',
      interview_history: []
    }
  },
  methods: {
    sendExit () {
      this.$emit('update-value')
    },
    async init() {
      const res = await getInterviewHistory({history_id: this.history_id})
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }

      this.interview_history = res.data.data
    }
  },
  mounted () {
    this.init()
  },
  created () {
    this.history_id = this.$route.query.history_id
  }
}
</script>

<style lang="less" scoped>
.interview-history {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .chat-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
  .chat-main {
    flex: 1;
    overflow-y: auto;
    border: 2px solid #a2c0f5;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .main-head {
      min-height: 20%;
      border-bottom: 2px solid #c5c4c4;
      margin-bottom: 20px;
      .ai-title {
        height: 42%;
      }
      .head-content {
        height: 58%;
        display: flex;
        align-items: center;
        .el-icon-s-custom {
          font-size: 32px;
          color: #2a6ee9;
          margin-right: 14px;
        }
        .name {
          margin-right: 20px;
          font-weight: bold;
          font-size: 18px;
        }
      }
    }
    .main-data {
      background-color: #ebf2ff;
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      .data-analysis {
        display: flex;
        .title {
          background-color: #2a6ee9;
          width: 68px;
          border-radius: 8px;
          text-align: center;
          height: 24px;
          line-height: 24px;
          font-weight: bold;
          color: #fff;
        }
        .analysis {
          flex: 1;
          padding: 4px;
          white-space: pre-wrap;
          font-weight: bold;
        }
      }
      .data-result {
        display: flex;
        margin-bottom: 10px;
        .title {
          background-color: #ffe17e;
          width: 68px;
          border-radius: 8px;
          text-align: center;
          height: 24px;
          line-height: 24px;
          font-weight: bold;
        }
        .result {
          flex: 1;
          padding: 4px;
        }
      }
      .data-question-topic {
        color: #4480ec;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
}
</style>